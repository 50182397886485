
.login-logo img{
  width: 60px;
  height: auto;
}

.date-range-with-no-edit-icon {
  .PrivateDateRangePickerToolbar-penIcon {
    display: none;
  }
}

.date-with-no-edit-icon {
  .PrivateDatePickerToolbar-penIcon {
    display: none;
  }
}

.dashboard-card{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  border-radius: 12px;
  border: none;
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: relative;
  border-radius: 12px !important;

  &::after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    border-radius: 50%;
    background: rgb(102, 102, 102);
    z-index: 1;
    top: -85px;
    right: -95px;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 210px;
    height: 210px;
    border-radius: 50%;
    background: rgb(102, 102, 102);
    top: -125px;
    right: -15px;
    opacity: 0.5;
  }
  .dashboard-card-icon{
    user-select: none;
  }
  .dashboard-card-title{
    user-select: none;
    font-weight: 500;
    font-size: 1.9rem;
  }
  .dashboard-card-subtitle{
    user-select: none;
    color: rgb(154, 154, 154);
  }
  .dashboard-card-value{
    color: rgb(102, 102, 102);
  }
  /* Blue */
  &.blue{
    .dashboard-card-title{
      color: #fff !important;
    }
    background-color: rgb(30, 136, 229) !important;
    &::after {
      background: rgb(21, 101, 192);
    }
    &::before {
      background: rgb(21, 101, 192);
    }
    .dashboard-card-icon{
      background: rgb(21, 101, 192);
    }
    .dashboard-card-value{
      color: #fff;
    }
    .dashboard-card-subtitle{
      color: rgb(144, 202, 249);
    }
  }

  /* Purple */
  &.purple{
    background-color: rgb(94, 53, 177) !important;
    .dashboard-card-title, .dashboard-card-value{
      color: #fff !important;
    }
    &::after, &::before, .dashboard-card-icon{
      background: rgb(69, 39, 160);
    }
    .dashboard-card-subtitle{
      color: rgb(179, 157, 219);
    }
  }

  /* Green */
  &.green{
    background-color: #24a666 !important;
    .dashboard-card-title, .dashboard-card-value{
      color: #fff !important;
    }
    &::after, &::before, .dashboard-card-icon{
      background: #00722f;
    }
    .dashboard-card-subtitle{
      color: #B9F6CA;
    }
  }
  /* Orange */
  &.orange {
    background-color: #D84315 !important;
    .dashboard-card-title, .dashboard-card-value{
      color: #fff !important;
    }
    &::after, &::before, .dashboard-card-icon{
      background: #FFAB91;
    }
    .dashboard-card-subtitle{
      color: #FBE9E7;
    }
  }
  /* Yellow */
  &.yellow {
    background-color: #FFC107 !important;
    .dashboard-card-title, .dashboard-card-value{
      color: #fff !important;
    }
    &::after, &::before, .dashboard-card-icon{
      background: #FFE57F;
    }
    .dashboard-card-subtitle{
      color: #fff8e1;
    }
  }

  /* Red */
  &.red {
    background-color: #ce3024 !important;
    .dashboard-card-title, .dashboard-card-value{
      color: #fff !important;
    }
    &::after, &::before, .dashboard-card-icon{
      background: #850f0f;
    }
    .dashboard-card-subtitle{
      color: #EF9A9A;
    }
  }
}

.dashboard-card-theme-2{
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  box-shadow: rgba(0,0,0,0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0,0,0,0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  
  .icn-wrapper{
    color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -16px;
    align-items: center;
    color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    box-shadow: rgba(0,0,0,0.14 ) 0rem 0.25rem 0.375rem -0.0625rem, rgba(64,64,64,0.4) 0rem 0.125rem 0.25rem -0.0625rem;
  }

  
  .dashboard-card-icon{
    user-select: none;
  }
  .dashboard-card-title{
    user-select: none;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 300;
    display: block;
  }
  .dashboard-card-subtitle{
    user-select: none;
    color: rgb(154, 154, 154);
  }
  .dashboard-card-value{
    display: block;
    margin: 0px;
    font-size: 1.5rem;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.00735em;
    opacity: 1;
    text-transform: none;
    text-decoration: none;
    color: rgb(52, 71, 103);
    text-align: end;
  }
  /* Blue */
  &.blue{
    .dashboard-card-icon{
      background: linear-gradient(358deg, #7373ed, #4390e7);
    }
    .MuiLinearProgress-root{
      background-color: #d5d8ff;
    }
    .MuiLinearProgress-bar{
      background-color: #4747c1;
    }
  }

  /* Purple */
  &.purple{
    .dashboard-card-icon{
      background: linear-gradient(358deg, #5e35b1, #ca33c0);
    }
    .MuiLinearProgress-root{
      background-color: #e7c7e5;
    }
    .MuiLinearProgress-bar{
      background-color: #ca33c0;
    }
  }

  /* Green */
  &.green{
    .dashboard-card-icon{
      background: linear-gradient(358deg, #146e00, #24a666);
    }
    .MuiLinearProgress-root{
      background-color: #badbcb;
    }
    .MuiLinearProgress-bar{
      background-color: #24a666;
    }
  }
  /* Orange */
  &.orange {
    .dashboard-card-icon{
      background: linear-gradient(358deg, #ca5a38, #ff4c13);
    }
    .MuiLinearProgress-root{
      background-color: #ffc1af;
    }
    .MuiLinearProgress-bar{
      background-color: #ff6b3e;
    }
  }
  /* Yellow */
  &.yellow {
    .dashboard-card-icon{
      background: linear-gradient(358deg, #ffc206, #F8DD07);

    }
   
  }

  /* Red */
  &.red {
    .dashboard-card-icon{
    background: linear-gradient(358deg, #a10e02, #ff1200);

    }
    .MuiLinearProgress-root{
      background-color: #fdbab5;
    }
    .MuiLinearProgress-bar{
      background-color: #ff1200;
    }
  }
}

// @media only screen
// and (max-width: 1540px) {

//   .drawer-mobile {
//     position: absolute !important;   
//     height: 100vh;
//   }
// }

.issue-chats {
  margin-top: 2rem;
  position: relative;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;

  .chat-body{
    width: auto;
    font-size: 14px;
    .text{
      padding: 6px 8px;
    }
    margin-bottom: 1rem;
    
  }
  .from-msg{
    float: right;
    clear: both;
    text-align: right;
    margin-left: 20%;
    .text{
      background-color: #5e4e7c;
      border-radius: 10px 0 10px 10px;
      color: #ffffff;
    }
    &::after {
      content: '';
      display: block;
    }
  }
  .reply-msg{
    float: left;
    clear: both;
    text-align: left;
    margin-right: 20%;
    .text{
      background-color: #e7e7e7;
      border-radius: 0 10px 10px 10px;
    }
    &::after {
      content: '';
      display: block;
    }
  }
}


/* Dashbiard navlink active */
.page-title{
  &::after{
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    margin: 0.19285em 0 0.423em;
    border-radius: 4px;
  }
  

  &.accent-primary::after{
    background: #0e1d34;
  }
  &.accent-primary-dark::after{
    background: #3E4A5C;
  }
  &.accent-primary-light::after{
    background: #3E4A5C;
  }
  &.accent-primary-lighter::after{
    background: #eaf0f0;
  }

  &.accent-secondary::after{
    background: #ffc118;
  }
  &.accent-secondary-dark::after{
    
    background: #b81700;
  }
  &.accent-secondary-light::after{
    background: #ee6350;
  }
  &.accent-secondary-lighter::after{
    background: #fdafa4;
  }
}
.header-menu-link{
  opacity: 0.8;
}
.header-menu-link.active{
  // border-bottom: 2px solid #000;
  font-weight: 900;
  color: #C74634;
  opacity: 1;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.app-page-container{
  .api-progress-container{
    visibility: hidden;
  }
  &.loading{
    pointer-events: none;
    position: relative;
    button[type='submit']{
      background-color: #c8c8c8;
      pointer-events: none;
    }

    .api-progress-container{
      visibility: visible;
    }

    .loading-wrapper{
      pointer-events: none;
      background-color: #f3f3f3;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: .5;
      z-index: 1;
    }
  }
}

.title-accent{
  &::after{
    content: '';
    display: block;
    width: 20px;
    height: 3px;
    margin: 0.19285em 0 0.423em;
    border-radius: 2px;
  }

  &.primary::after{
    background: #0e1d34;
  }
  &.primary-dark::after{
    background: #3E4A5C;
  }
  &.primary-light::after{
    background: #3E4A5C;
  }
  &.primary-lighter::after{
    background: #eaf0f0;
  }

  &.secondary::after{
    background: #775930;
  }
  &.secondary-dark::after{
    background: #b81700;
  }
  &.secondary-light::after{
    background: #ee6350;
  }
  &.secondary-lighter::after{
    background: #fdafa4;
  }
  &.white::after{
    background: #ffffff;
  }
}

@media only screen
and (max-width: 1200) {
  .drawer-mobile {
    position: absolute !important;   
    height: 100vh;
  }
}

.simplebar-track{
  opacity: 0.4;
}

.readonly-form {
  .Mui-disabled:not(button) {
    -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
    color: rgba(0, 0, 0, 1);
  }
}

.inline-circular-progress {
  display: none;
  &.loading {
    display: flex;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #555;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #fff;
}

.simplebar-content-wrapper:focus{
  outline: none;
}

.card {
  position: relative;
  &.pattern {
    &::before{
      opacity: 0.16;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-repeat: repeat;
      background-size: 500px 500px;
      content: "";
      // mix-blend-mode: overlay;
      filter: opacity(0.5);
    }
    &.wave-pattern::before{
      background-image: url('../public/assets/images/patterns/wave-lines.png')
    }   
    &.worn-pattern::before{
      background-image: url('../public/assets/images/patterns/worn.png')
    }   
    &.circle-pattern::before{
      filter: opacity(0.3);
      background-image: url('../public/assets/images/patterns/circle-line.png')
    }   
  }
}